import React from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import CF7Basic from '../Form/CF7/Basic'
import SocialIcons from '../SocialIcons'
function Footer() {

  return (
    <footer className='master-footer flex-12 c1'>
      <div id="contact" className='anchor' style={{ bottom: '0', top: "initial" }}></div>
      <div className="intro-box fs-85 clead x1">
        <h4>kontakt</h4>
      </div>

      {/* <div className="to-top c0 x0">
				<a href="#top" aria-label="Take me to top"><FontAwesomeIcon icon={faArrowUp} /></a>
			</div> */}



      <div className='span-6 span-12-mobile questions flex ac-center padd-top-2 padd-bottom-2'>
        <div className='padd-2 fs-125'>
          <h4>Masz pytania? <br></br> Skontaktuj się z nami</h4>
          <SocialIcons classes="x0 t" hint='left' />

        </div>
      </div>
      <div className='span-6 span-12-mobile padd-top-2 padd-bottom-2'>
        <div className="contact padd-2 fs-85">
          <CF7Basic />
        </div>
      </div>
      <div className='span-12'>
        <div className='copy c3'>
          <p>Copyright &copy; 2022. Made with <FontAwesomeIcon icon={faHeart} /> by <span className='hint--rounded hint--top' data-hint='Much love!'>Bart</span>.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer